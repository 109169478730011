import Close from "@mui/icons-material/Close";
import Send from "@mui/icons-material/Send";
import {
  Box,
  CircularProgress,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Theme } from "@mui/system";

type Props = {
  title: string;
  onClose: () => void;
  isSubmitting?: boolean;
  canSubmit?: boolean;
  onSubmit?: () => void;
};

export default function DialogHeader({
  title,
  onClose,
  onSubmit,
  canSubmit,
  isSubmitting,
}: Props) {
  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const showSubmitButton = onSubmit && isSmDown;

  return (
    <DialogTitle
      sx={(theme) => ({
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingRight: "8px",

        [theme.breakpoints.down("sm")]: {
          padding: "8px 16px 8px 12px",
        },
      })}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent={showSubmitButton ? "space-between" : "flex-start"}
      >
        <IconButton
          color="inherit"
          size="large"
          sx={{
            display: { xs: "flex", sm: "none" },
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>

        <Typography variant="h3">{title}</Typography>

        <IconButton
          color="inherit"
          size="large"
          sx={{
            display: { xs: "none", sm: "flex" },
            ml: "auto",
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>

        {showSubmitButton && (
          <IconButton
            disabled={!canSubmit}
            sx={{
              borderRadius: "8px",
              padding: "10px",
              color: "#fff",
              background: "#191919",
              "&:hover": {
                background: "#000",
              },
              "&.Mui-disabled": {
                background: "#b2b3b2",
                color: "#fff",
              },
            }}
            onClick={onSubmit}
          >
            {isSubmitting ? (
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            ) : (
              <Send fontSize="small" />
            )}
          </IconButton>
        )}
      </Box>
    </DialogTitle>
  );
}
