import { Close, Menu, Notifications } from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  Container,
  IconButton,
  Toolbar,
  styled,
} from "@mui/material";
import { useState } from "react";

import AppHeaderUserMenu from "components/navigation/AppHeaderUserMenu";
import NotificationsDropdown from "components/notifications/NotificationsDropdown";
import { useLayout } from "components/shared/AppLayout";
import FavIcon from "components/shared/FavIcon";
import useCommunity from "hooks/useCommunity";
import useIdentity from "hooks/useIdentity";
import useNotifications from "hooks/useNotifications";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    color: "#fff",
    border: "1px solid #fff",
    backgroundColor: "#f44336",
    fontSize: "11px",
    padding: "0 4px",
    height: "18px",
    top: "32%",
    right: "5%",
  },
}));

type Props = {
  siteName: string;
  siteLogo: string;
};

export default function AppHeader({ siteName, siteLogo }: Props) {
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const {
    error,
    loading,
    markAllAsRead,
    markAllAsSeen,
    notifications,
    unseenCount,
    readNotification,
  } = useNotifications();

  const { drawerOpen, setDrawerOpen } = useLayout();

  const { communityCode, gate } = useIdentity();
  const { community } = useCommunity({ code: communityCode });

  const handleNotificationsClick = (event) => {
    markAllAsSeen();
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenuClose = () => {
    setNotificationsAnchorEl(null);
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.paper,
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      })}
    >
      <FavIcon />
      <Container
        maxWidth="xl"
        sx={{
          maxWidth: "1280px",
          padding: 0,
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 20px",
          }}
        >
          <Box alignItems="center" display="flex" gap="6px">
            <IconButton
              aria-label="open drawer"
              color="inherit"
              size="large"
              sx={{ display: { xs: "flex", lg: "none" } }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              {!drawerOpen ? <Menu /> : <Close />}
            </IconButton>
            <img alt={siteName} height="30px" src={siteLogo} />
          </Box>
          <Box
            alignItems="center"
            display="flex"
            sx={(theme) => ({
              margin: "0 -12px",
              "& > *": {
                margin: "0 12px",
              },
              [theme.breakpoints.down("sm")]: {
                margin: "0 -4px",
                "& > *": {
                  margin: "0 4px",
                },
              },
            })}
          >
            {!gate.includes("dunning") && !gate.includes("maintenance") && (
              <>
                <IconButton
                  aria-controls="notifications-menu"
                  aria-haspopup="true"
                  className="pendo_web-notifications-bellicon"
                  size="large"
                  onClick={handleNotificationsClick}
                >
                  <StyledBadge badgeContent={unseenCount} overlap="circular">
                    <Notifications style={{ color: "#000" }} />
                  </StyledBadge>
                </IconButton>
                <NotificationsDropdown
                  anchorRef={notificationsAnchorEl}
                  error={error}
                  handleClose={handleNotificationsMenuClose}
                  loading={loading}
                  markAllAsRead={markAllAsRead}
                  notifications={notifications}
                  readNotification={readNotification}
                />
              </>
            )}

            <AppHeaderUserMenu items={community?.navMore || []} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
