import { zodResolver } from "@hookform/resolvers/zod";
import { Add, DateRange } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  PickersLayoutContentWrapper,
  PickersLayoutRoot,
  usePickerLayout,
} from "@mui/x-date-pickers";
import { addDays, startOfDay } from "date-fns";
import Link from "next/link";
import {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";

import DatePicker from "components/shared/DatePicker";
import DialogHeader from "components/shared/DialogHeader";

import { ActivityEditorData } from "../ActivityEditorDialog";

const maxQuestionLength = 140;
const maxOptionLength = 60;
const minOptions = 2;
const maxOptions = 5;
const optionPlaceholders = [
  "E.g., Morning",
  "E.g., Evening",
  "E.g., Afternoon",
  "E.g., All day",
  "E.g., Other (please comment)",
];
const defaultDuration = "1 week";

const requiredMsg = "This is a required field.";
export const schema = z.object({
  question: z.string().min(1, { message: requiredMsg }).max(maxQuestionLength),
  options: z.array(
    z.object({
      value: z.string().min(1, { message: requiredMsg }).max(maxOptionLength),
    })
  ),
  closeAt: z
    .date({
      errorMap: (issue, ctx) => {
        switch (issue.code) {
          case z.ZodIssueCode.invalid_date:
            return { message: "Must be a valid date" };
          case z.ZodIssueCode.invalid_type:
            return { message: requiredMsg };
          default:
            return { message: ctx.defaultError };
        }
      },
    })
    .min(addDays(startOfDay(new Date()), 1), {
      message: "Date must be at least one day from now",
    })
    .max(addDays(startOfDay(new Date()), 45), {
      message: "Date must be at most 45 days from now",
    }),
  duration: z.enum(["1 day", "1 week", "2 weeks", "custom"]),
});

export type Schema = z.infer<typeof schema>;

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  setActivityEditorData: Dispatch<SetStateAction<ActivityEditorData>>;
  setIsDirty: (value: boolean) => void;
  activityEditorData: ActivityEditorData;
};

function ErrorHelperText({ error, errorMessage = null, maxLength, value }) {
  return (
    <FormHelperText error={error} sx={{ textAlign: "right" }}>
      {error && errorMessage
        ? errorMessage
        : `${value?.length ?? "0"}/${maxLength}`}
    </FormHelperText>
  );
}

function CustomDatePickerLayout(props) {
  const { toolbar, tabs, content } = usePickerLayout(props);

  return (
    <PickersLayoutRoot ownerState={props}>
      {toolbar}
      <PickersLayoutContentWrapper>
        {tabs}
        {content}
        <Typography color="textSecondary" sx={{ p: 2 }} variant="body2">
          Polls are limited to a duration of 45 days.
        </Typography>
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
}

export default function PollEditorDialog({
  activityEditorData,
  onClose,
  onConfirm,
  setActivityEditorData,
  setIsDirty,
}: Props) {
  const [optionToRemove, setOptionToRemove] = useState(null);
  const [customDatePickerOpen, setCustomDatePickerOpen] = useState(false);

  const durationValues = useMemo(
    () => ({
      "1 day": {
        label: "1 day",
        date: addDays(new Date(), 1),
      },
      "1 week": {
        label: "1 week",
        date: addDays(new Date(), 7),
      },
      "2 weeks": {
        label: "2 weeks",
        date: addDays(new Date(), 14),
      },
      custom: {
        label: (
          <Box alignItems="center" display="flex" gap={1}>
            <DateRange /> Custom
          </Box>
        ),
        date: null,
      },
    }),
    []
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    control,
    getValues,
    setValue,
    reset,
  } = useForm<Schema>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      question: "",
      options: [...Array(minOptions).fill({ value: "" })],
      closeAt: durationValues[defaultDuration].date,
      duration: defaultDuration,
    },
  });

  const customDatePickerIsVisible = watch("duration") === "custom";

  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
    replace: replaceOptions,
  } = useFieldArray({
    control,
    name: "options",
  });

  // Run only after the first render and form initialization to prevent blinking
  // Set the poll data if the form modal is being reopened for editing. Setting it like this
  // instead of in the defaultValues allows the isDirty state to be correctly set
  useLayoutEffect(() => {
    if (activityEditorData?.poll) {
      const pollOptions = activityEditorData?.poll?.data?.options?.map(
        (opt) => ({
          value: opt,
        })
      ) || [...Array(minOptions).fill({ value: "" })];

      reset(
        {
          question: activityEditorData?.poll?.data?.question || "",
          options: pollOptions,
          closeAt: activityEditorData?.poll?.data?.closeAt
            ? new Date(activityEditorData?.poll?.data?.closeAt)
            : durationValues[defaultDuration].date,
          duration: activityEditorData?.poll?.form?.duration || defaultDuration,
        },
        { keepDirty: true }
      );
      replaceOptions(pollOptions);
    }
  }, [reset, activityEditorData?.poll, durationValues, replaceOptions]);

  // Reset function also resets the dirty state, so we need to keep track of isDirty manually
  const handleInputChange = () => {
    const formData = getValues();
    const isFormDirty =
      formData.question !== "" ||
      formData.options.some((opt) => opt.value !== "");
    setIsDirty(isFormDirty);
  };

  const onSubmit = (data: Schema) => {
    setActivityEditorData((prevState) => ({
      ...prevState,
      poll: {
        data: {
          ...prevState.poll.data,
          ...data,
          options: data.options.map((opt) => opt.value),
        },
        form: {
          duration: data?.duration,
        },
      } as ActivityEditorData["poll"],
    }));
    onConfirm();
  };

  const handleRemoveOptionConfirm = () => {
    removeOption(optionToRemove);
    setOptionToRemove(null);
  };

  const handleRemoveOption = (i: number) => {
    if (getValues(`options.${i}.value`)) {
      setOptionToRemove(i);
    } else {
      removeOption(i);
    }
  };

  return (
    <>
      <DialogHeader title="Create a poll" onClose={onClose} />

      <DialogContent dividers sx={{ borderBottom: 0 }}>
        <Box mb={2}>
          <Typography color="textSecondary" variant="caption">
            Indicates a required field{" "}
          </Typography>
          <Typography color="error" variant="caption">
            *
          </Typography>
        </Box>

        <Box mb={1}>
          <TextField
            fullWidth
            multiline
            error={!!errors?.question}
            label="Question *"
            maxRows={3}
            placeholder="E.g., What time of the day you are the most productive?"
            size="small"
            variant="outlined"
            {...register("question")}
            onChange={(e) => {
              setValue("question", e.target.value, { shouldValidate: true });
              handleInputChange();
            }}
          />

          <ErrorHelperText
            error={errors?.question}
            maxLength={maxQuestionLength}
            value={watch("question")}
          />
        </Box>

        {optionFields.map((field, i) => {
          const optionNumber = i + 1;

          return (
            <Box key={field.id} mb={1}>
              {optionNumber > minOptions && (
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="link" onClick={() => handleRemoveOption(i)}>
                    Remove
                  </Button>
                </Box>
              )}

              <TextField
                fullWidth
                multiline
                error={!!errors?.options?.[i]?.value}
                label={`Option ${optionNumber} *`}
                maxRows={3}
                placeholder={optionPlaceholders[i]}
                size="small"
                variant="outlined"
                {...register(`options.${i}.value`)}
                onChange={(e) => {
                  setValue(`options.${i}.value`, e.target.value, {
                    shouldValidate: true,
                  });
                  handleInputChange();
                }}
              />

              <ErrorHelperText
                error={errors?.options?.[i]?.value}
                errorMessage={
                  i >= minOptions &&
                  errors?.options?.[i]?.value?.type !== "too_big" &&
                  "Enter text or remove this option to continue"
                }
                maxLength={maxOptionLength}
                value={watch(`options.${i}.value`)}
              />
            </Box>
          );
        })}

        <Box display="flex" justifyContent="flex-end" my={2}>
          <Button
            disabled={optionFields.length === maxOptions}
            startIcon={<Add />}
            variant="contained"
            onClick={() => appendOption({ value: "" })}
          >
            Add Option
          </Button>
        </Box>

        <Box sx={{ mb: 3 }}>
          <input {...register("closeAt")} type="hidden" />

          <TextField
            fullWidth
            select
            error={!!errors.closeAt && !customDatePickerIsVisible}
            label="Poll duration"
            size="small"
            value={watch("duration")}
            onChange={(e) => {
              setCustomDatePickerOpen(e.target.value === "custom");
              setValue("duration", e.target.value as Schema["duration"]);
              setValue("closeAt", durationValues[e.target.value].date, {
                // Don't validate when custom is selected otherwise the
                // the date picker field will immediately show an error
                shouldValidate: e.target.value !== "custom",
              });
              handleInputChange();
            }}
          >
            {Object.keys(durationValues).map((key) => (
              <MenuItem key={key} value={key}>
                {durationValues[key].label}
              </MenuItem>
            ))}
          </TextField>

          {customDatePickerIsVisible && (
            <DatePicker
              disablePast
              defaultValue={watch("closeAt")}
              maxDate={addDays(new Date(), 45)}
              minDate={addDays(new Date(), 1)}
              open={customDatePickerOpen}
              slots={{
                layout: CustomDatePickerLayout,
              }}
              textFieldProps={{
                fullWidth: true,
                error: !!errors?.closeAt,
                sx: { mt: 3 },
              }}
              onChange={(date) => {
                setValue("closeAt", date, { shouldValidate: true });
                handleInputChange();
              }}
              onClose={() => {
                setCustomDatePickerOpen(false);
              }}
              onOpen={() => {
                setCustomDatePickerOpen(true);
              }}
            />
          )}

          {errors?.closeAt && (
            <Box display="flex" justifyContent="flex-end" mt={0.5}>
              <Typography color="error" variant="caption">
                {errors?.closeAt?.message}
              </Typography>
            </Box>
          )}
        </Box>

        <Typography>
          Polls must follow our{" "}
          <Link href="/guidelines" target="_blank">
            community guidelines
          </Link>
          .
        </Typography>
      </DialogContent>

      <DialogActions sx={{ px: 3 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!isValid}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </DialogActions>

      <Dialog maxWidth="xs" open={!!optionToRemove}>
        <DialogTitle>Remove option</DialogTitle>

        <DialogContent>
          <Typography color="textSecondary">
            Are you sure you want to remove this option from the poll?
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOptionToRemove(null)}>Cancel</Button>
          <Button variant="contained" onClick={handleRemoveOptionConfirm}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
