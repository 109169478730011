import { useFlags } from "launchdarkly-react-client-sdk";
import router from "next/router";

import Feed from "components/feed";

function Index() {
  const { permHomePage } = useFlags();

  if (permHomePage === "expertpanels") {
    router.replace("/publishing/expert-panels");
  }

  return <Feed />;
}

export default Index;
