import { CCO_REACTIONS } from "lib/motorcade/constants";

export function findActivity({ activityId, feed }) {
  const activity = feed.pages
    .map((page) => page.results)
    .flat()
    .map((result) => (result?.id ? (result.activities ?? result) : []))
    .flat()
    .find((a) => a.id === activityId);

  return { ...activity };
}

export function findComment({ activity, commentId }) {
  const comment = activity.latest_reactions.comment.find(
    (c) => c.id === commentId
  );
  return { ...comment };
}

export function findReply({ comment, replyId }) {
  const reply = comment.latest_children.reply.find((r) => r.id === replyId);
  return { ...reply };
}

export function findMyReaction({ activity, myId }) {
  return Object.entries(activity.latest_children).find(([_, childReactions]) =>
    childReactions.find(
      (reaction) =>
        reaction.user.id === myId && CCO_REACTIONS.includes(reaction.kind)
    )
  )?.[1]?.[0];
}
