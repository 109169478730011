import { useApolloClient, useMutation } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import {
  USER_ORIENTATION_DISMISS_MUTATION,
  USER_ORIENTATION_MOBILE_APP_SKIP_MUTATION,
} from "graphql/user/mutations";

import useMe from "./useMe";

export type StepContent = {
  mediaUrl: string;
  title: string;
  text: string;
  buttonText?: string;
  hasSkip?: boolean;
  link?: string;
  stepCompleted?: boolean;
};

export type Steps = {
  profile: StepContent;
  discussion: StepContent;
  download: StepContent;
  expertPanel?: StepContent;
  completed?: StepContent;
};

export function useOrientationTasks() {
  const client = useApolloClient();
  const { orientation: orientationData, membershipTier, id: meId } = useMe();
  const { permExpertPanels } = useFlags();

  const [orientationDismiss] = useMutation(USER_ORIENTATION_DISMISS_MUTATION, {
    update(cache) {
      cache.modify({
        id: client.cache.identify({ __typename: "Me", id: meId }),
        fields: {
          orientation(existingOrientation = {}) {
            return {
              ...existingOrientation,
              isDismissed: true,
              tasksDismissedAt: new Date().toISOString(),
            };
          },
        },
      });
    },
  });

  const [mobileAppSkip] = useMutation(
    USER_ORIENTATION_MOBILE_APP_SKIP_MUTATION,
    {
      update(cache) {
        cache.modify({
          id: client.cache.identify({ __typename: "Me", id: meId }),
          fields: {
            orientation(existingOrientation = {}) {
              return {
                ...existingOrientation,
                mobileAppSkippedAt: new Date().toISOString(),
              };
            },
          },
        });
      },
    }
  );

  const [hasSeenOrientationCompleted, setHasSeenOrientationCompleted] =
    useState(
      () => localStorage.getItem("hasSeenOrientationCompleted") === "true"
    );

  const router = useRouter();

  useEffect(() => {
    const handleStateUpdate = () => {
      if (orientationData?.isCompleted && !hasSeenOrientationCompleted) {
        setHasSeenOrientationCompleted(true);
        localStorage.setItem("hasSeenOrientationCompleted", "true");
      }
    };

    if (
      orientationData?.isCompleted &&
      !hasSeenOrientationCompleted &&
      router.pathname === "/"
    ) {
      window.addEventListener("beforeunload", handleStateUpdate);
      router.events.on("routeChangeStart", handleStateUpdate);
    }

    return () => {
      window.removeEventListener("beforeunload", handleStateUpdate);
      router.events.off("routeChangeStart", handleStateUpdate);
    };
  }, [hasSeenOrientationCompleted, orientationData?.isCompleted, router]);

  const steps = useMemo<Steps>(() => {
    const completedStep: StepContent = {
      title: "You've completed your orientation!",
      text: "You've completed all the starting tasks, now enjoy the community!",
      buttonText: "Close",
      mediaUrl:
        "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-completed-compressed.jpeg",
    };
    const baseSteps: Steps = {
      profile: {
        title: "Make your profile stand out",
        text: "Update your profile to tell others about yourself, your work, and any additional information.",
        buttonText: "Take me there",
        link: "/profile",
        mediaUrl:
          "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-profile-compressed.jpg",
        stepCompleted: orientationData?.profileCompletedAt,
      },
      discussion: {
        title: "Start a discussion",
        text: "Create a post to introduce yourself to your peers.",
        buttonText: "Get started",
        mediaUrl:
          "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-discussion-compressed.jpeg",
        stepCompleted: orientationData?.firstFeedPostAt,
      },
      expertPanel: {
        title: "Contribute to an Expert Panel",
        text: "Answer questions that need your insights and get published!",
        buttonText: "Take me there",
        link: "/publishing/expert-panels?tab=open",
        mediaUrl:
          "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-eps-compressed.jpeg",
        stepCompleted: orientationData?.firstExpertPanelsAnswerAt,
      },
      download: {
        title: "Download the app",
        text: "Did you know there's a mobile app? Download and log in to check it out!",
        buttonText: "Take me there",
        mediaUrl:
          "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-mobile-compressed.jpeg",
        hasSkip: true,
        stepCompleted:
          orientationData?.firstAccessedIosAt ||
          orientationData?.firstAccessedAndroidAt ||
          orientationData?.mobileAppSkippedAt,
      },
    };

    if (orientationData?.isCompleted) {
      baseSteps.completed = completedStep;
    }

    if (membershipTier === "premium") {
      baseSteps.profile = {
        title: "Make your profile the best it can be!",
        text: "Update your profile to tell others about yourself, learn about premium, and more with our experts.",
        buttonText: "Schedule a call",
        link: "/profile",
        stepCompleted: orientationData?.profileCompletedAt,
        mediaUrl:
          "https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-profile-compressed.jpg",
      };
    }

    if (permExpertPanels !== true) {
      delete baseSteps.expertPanel;
    }

    return baseSteps;
  }, [orientationData, membershipTier, permExpertPanels]);

  const progressStepKeys = useMemo(
    () =>
      Object.keys(steps).filter(
        (key) => key !== "completed"
      ) as (keyof Steps)[],
    [steps]
  );

  const allStepKeys = useMemo(
    () => Object.keys(steps) as (keyof Steps)[],
    [steps]
  );

  const stepsCompleted = progressStepKeys.filter(
    (key) => steps[key]?.stepCompleted
  ).length;

  const markStepAsCompleted = async (
    stepKey: keyof Steps,
    mobileSkip?: boolean
  ) => {
    if (!orientationData?.isInOrientation) return;

    try {
      client.cache.modify({
        id: client.cache.identify({ __typename: "Me", id: meId }),
        fields: {
          orientation(existingOrientation = {}) {
            const updatedOrientation = { ...existingOrientation };

            const completeStep = (field: string) => {
              updatedOrientation[field] = new Date().toISOString();
            };

            switch (stepKey) {
              case "profile":
                completeStep("profileCompletedAt");
                break;
              case "discussion":
                completeStep("firstFeedPostAt");
                break;
              case "expertPanel":
                completeStep("firstExpertPanelsAnswerAt");
                break;
              default:
                break;
            }
            if (mobileSkip) completeStep("mobileAppSkippedAt");

            const isAllStepsCompleted = () => {
              return (
                updatedOrientation.profileCompletedAt &&
                updatedOrientation.firstFeedPostAt &&
                updatedOrientation.firstExpertPanelsAnswerAt &&
                (updatedOrientation.firstAccessedIosAt ||
                  updatedOrientation.firstAccessedAndroidAt ||
                  updatedOrientation.mobileAppSkippedAt)
              );
            };

            if (isAllStepsCompleted()) {
              updatedOrientation.isCompleted = true;
            }

            return updatedOrientation;
          },
        },
      });
    } catch (error) {
      console.error("Error updating member orientation cache", error);
    }
  };
  return {
    steps,
    stepKeys: allStepKeys,
    progressStepKeys,
    stepsCompleted,
    markStepAsCompleted,
    isStepCompleted: (stepKey: keyof Steps) => steps[stepKey]?.stepCompleted,
    isDismissedBanner: orientationData?.isDismissed,
    hasDismissedBanner: orientationData?.tasksDismissedAt,
    isInOrientation: orientationData?.isInOrientation,
    handleDismiss: orientationDismiss,
    hasSeenOrientationCompleted,
    setHasSeenOrientationCompleted,
    orientationCompleted: orientationData?.isCompleted,
    mobileAppSkip,
  };
}
