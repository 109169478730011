import { useChannelDMs } from "@communityco/chat-state";
import { ExpandLess } from "@mui/icons-material";
import HandshakeIcon from "@mui/icons-material/Handshake";
import {
  Box,
  Chip,
  Collapse,
  Divider,
  Drawer,
  Grid,
  List,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";
import { useMemo, useRef, useState } from "react";

import { Exec as ExecIcon, Groups } from "components/icons";
import Article from "components/icons/Article";
import ChatBubble from "components/icons/ChatBubble";
import Event from "components/icons/Event";
import ExpandMore from "components/icons/ExpandMore";
import ExpertPanelQuote from "components/icons/ExpertPanelQuote";
import HelpOutline from "components/icons/HelpOutline";
import Home from "components/icons/Home";
import InsertDriveFile from "components/icons/InsertDriveFile";
import PersonSearch from "components/icons/PersonSearch";
import Smartphone from "components/icons/Smartphone";
import NavigationHintIndicator from "components/navigation/NavigationHintIndicator";
import NavigationMenuItem from "components/navigation/NavigationMenuItem";
import MobileDownloadDialog from "components/OrientationBanner/MobileDownloadDialog";
import { useLayout } from "components/shared/AppLayout";
import useExpertPanelOpenQuestions from "hooks/useExpertPanelOpenQuestions";

const StyledChip = styled(Chip)(() => ({
  height: "20px",

  "& .MuiChip-label": {
    padding: "0 6.5px",
    fontWeight: 500,
    fontSize: "12px",
  },
}));

function Nav() {
  const navRef = useRef(null);
  const router = useRouter();
  const [publishingOpen, setPublishingOpen] = useState(false);

  const { hasNewUnseenQuestions } = useExpertPanelOpenQuestions();
  const { channels } = useChannelDMs();
  const {
    permExecPerks,
    permExpertPanels,
    permGroups,
    permHelpCenter,
    permPublishing,
    permEvents,
    permReferMemberCopy,
    permHomePage,
  } = useFlags();
  const [mobileDownloadDialogOpen, setMobileDownloadDialogOpen] =
    useState(false);

  const unreadCount = useMemo(
    () =>
      channels.reduce((totalUnreadMessages, channel) => {
        if (channel?.unreadCount > 0) {
          return totalUnreadMessages + channel.unreadCount;
        }
        return totalUnreadMessages;
      }, 0),
    [channels]
  );

  const shownUnreadCount = unreadCount > 99 ? "99+" : unreadCount;
  const articlesRoute = router.pathname === "/publishing";
  const expertPanelsRoute = router.pathname.startsWith(
    "/publishing/expert-panels"
  );

  return (
    <Box
      ref={navRef}
      component="nav"
      sx={(theme) => ({
        height: `calc(var(--vh) - ${theme.mixins.toolbar.minHeight}px)`,
        overflowY: "auto",
        borderRight: "1px solid rgba(0,0,0,0.12)",

        [theme.breakpoints.down("lg")]: {
          marginTop: `${theme.mixins.toolbar.minHeight}px`,
          width: 296,
        },
      })}
    >
      <MobileDownloadDialog
        open={mobileDownloadDialogOpen}
        onClose={() => setMobileDownloadDialogOpen(false)}
      />
      <NavigationHintIndicator searchElement={navRef} />
      <Box
        px={2}
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <List
          disablePadding
          component="nav"
          sx={{
            margin: (theme) => theme.spacing(2, "auto"),
          }}
        >
          {permHomePage === "default" && (
            <NavigationMenuItem
              className="pendo_web-leftnav-home"
              href="/"
              icon={<Home />}
              selected={router.pathname === "/"}
              title="Home"
            />
          )}

          {permPublishing === false && permExpertPanels === true && (
            <NavigationMenuItem
              className="pendo_web-leftnav-expertpanels"
              href="/publishing/expert-panels"
              icon={<ExpertPanelQuote />}
              selected={expertPanelsRoute}
              title="Expert Panels"
            />
          )}

          {permPublishing === true && (
            <NavigationMenuItem
              endAdornment={
                publishingOpen ? (
                  <ExpandLess />
                ) : (
                  <Box alignItems="center" display="flex" gap="2px">
                    {hasNewUnseenQuestions && (
                      <StyledChip
                        label="New"
                        sx={{
                          backgroundColor: "warning.light",
                        }}
                      />
                    )}

                    <ExpandMore />
                  </Box>
                )
              }
              icon={<Article />}
              selected={!publishingOpen && (articlesRoute || expertPanelsRoute)}
              title="Publishing"
              onClick={(e) => {
                e.stopPropagation();
                setPublishingOpen((prev) => !prev);
              }}
            />
          )}

          <Collapse in={publishingOpen} sx={{ pl: 3 }}>
            <List>
              {permPublishing === true && (
                <NavigationMenuItem
                  className="pendo_web-leftnav-articles"
                  href="/publishing"
                  icon={<InsertDriveFile />}
                  selected={articlesRoute}
                  title="Articles"
                />
              )}

              {permExpertPanels === true && (
                <NavigationMenuItem
                  className="pendo_web-leftnav-expertpanels"
                  endAdornment={
                    hasNewUnseenQuestions && (
                      <StyledChip
                        label="New"
                        sx={{
                          backgroundColor: "warning.light",
                        }}
                      />
                    )
                  }
                  href="/publishing/expert-panels"
                  icon={<ExpertPanelQuote />}
                  selected={expertPanelsRoute}
                  title="Expert Panels"
                />
              )}
            </List>
          </Collapse>

          {permGroups === true && (
            <NavigationMenuItem
              className="pendo_web-leftnav-groups"
              href="/groups?view=AllGroupsList"
              icon={<Groups />}
              selected={router.pathname.startsWith("/groups")}
              title="Groups"
            />
          )}

          <NavigationMenuItem
            className="pendo_web-leftnav-messages"
            endAdornment={
              unreadCount > 0 && (
                <StyledChip color="error" label={shownUnreadCount} />
              )
            }
            href="/messages"
            icon={<ChatBubble />}
            selected={router.pathname.startsWith("/messages")}
            title="Messages"
          />

          {permEvents === true && (
            <NavigationMenuItem
              className="pendo_web-leftnav-events"
              href="/events"
              icon={<Event />}
              selected={router.pathname.startsWith("/events")}
              title="Events"
            />
          )}

          <NavigationMenuItem
            className="pendo_web-leftnav-memberdirectory"
            href="/members"
            icon={<PersonSearch />}
            selected={router.pathname.startsWith("/members")}
            title="Member directory"
          />

          {permExecPerks === true && (
            <NavigationMenuItem
              className="pendo_web-leftnav-exec"
              href="/exec"
              icon={<ExecIcon />}
              selected={router.pathname.startsWith("/exec")}
              title="EXEC perks"
            />
          )}

          {permReferMemberCopy?.confirmation && (
            <NavigationMenuItem
              className="pendo_web-leftnav-refer"
              href="/refer"
              icon={<HandshakeIcon />}
              selected={router.pathname.startsWith("/refer")}
              title="Refer members"
            />
          )}

          {permHelpCenter === true && (
            <NavigationMenuItem
              className="pendo_web-leftnav-helpcenter"
              href="/help-center"
              icon={<HelpOutline />}
              selected={router.pathname.startsWith("/help-center")}
              title="Help Center"
            />
          )}

          <Divider />

          <Box
            sx={(theme) => ({
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              my: 2,
              color: theme.palette.secondary.main,
              cursor: "pointer",
            })}
            onClick={() => setMobileDownloadDialogOpen(true)}
          >
            <Smartphone />
            <Typography pl={1} variant="body2">
              Check out our mobile app
            </Typography>
          </Box>
        </List>
      </Box>
    </Box>
  );
}

export default function LeftNav() {
  const { drawerOpen, setDrawerOpen } = useLayout();

  return (
    <>
      <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs={3}>
        <Nav />
      </Grid>

      <Drawer
        ModalProps={{
          keepMounted: true,
        }}
        open={drawerOpen}
        sx={{ display: { xs: "block", lg: "none" } }}
        variant="temporary"
        onClick={() => setDrawerOpen(false)}
        onClose={() => setDrawerOpen(false)}
      >
        <Nav />
      </Drawer>
    </>
  );
}
