import { gql } from "@apollo/client";

import {
  ME_ACCOUNT_SETTINGS_FRAGMENT,
  ME_FULL_FRAGMENT,
  ME_ONBOARDING_FRAGMENT,
} from "graphql/me/fragments";

export const ONBOARDING_ME = gql`
  query me {
    me {
      ...MeOnboarding_Fragment
    }
  }
  ${ME_ONBOARDING_FRAGMENT}
`;

export const ME_QUERY = gql`
  query {
    me {
      ...MeFull_Fragment
    }
  }
  ${ME_FULL_FRAGMENT}
`;

export const ME_ACCOUNT_SETTINGS_QUERY = gql`
  query {
    me {
      ...MeAccountSettings_Fragment
    }
  }
  ${ME_ACCOUNT_SETTINGS_FRAGMENT}
`;

export const ME_ONBOARDING_URL_QUERY = gql`
  query {
    me {
      onboardingUrl
    }
  }
`;
